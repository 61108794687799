<template>
  <div></div>
</template>

<script>
export default {
  name: 'Logout',
  components: {
  },
  created () {
    this.initialize();
  },
  methods: {
    async initialize () {
      this.$store.dispatch('signOut');
      this.$router.push({name: 'login'});
    },
  }
}
</script>

<style scoped>
</style>
